<template>
  <div class="bg_lightgray">
    <div id="section_panoramicImage" class="content anchor">
      <div class="content_header">
        <p>超高画質360度画像</p>
      </div>
      <div class="content_body">
        <p class="content_body__description centered">
          弊社は、国宝級の文化財を所有する寺院や京都の町屋など、数多くの撮影実績を持っています。この中の一部である超高画質360度パノラマ画像をご覧ください。画像をタッチ・クリックすることにより拡大したり、360度映像を楽しむことができます。
        </p>
        <ul class="nav nav-tabs nav-fill" id="vrContents" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="content1-tab"
              data-toggle="tab"
              href="#content1"
              role="tab"
              aria-controls="content1"
              aria-selected="true"
              >仁和寺</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="content2-tab"
              data-toggle="tab"
              href="#content2"
              role="tab"
              aria-controls="content2"
              aria-selected="false"
              >金剛福寺</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="content3-tab"
              data-toggle="tab"
              href="#content3"
              role="tab"
              aria-controls="content3"
              aria-selected="false"
              >冨田屋</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="content4-tab"
              data-toggle="tab"
              href="#content4"
              role="tab"
              aria-controls="content4"
              aria-selected="false"
              >西明寺</a
            >
          </li>
        </ul>
        <div class="tab-content tab_images" id="myTabContent">
          <div
            class="tab-pane fade tab_images show active"
            id="content1"
            role="tabpanel"
            aria-labelledby="content1-tab"
          >
            <VR src="https://aitrec.jp/VR/test/100K/tour.html" />
          </div>
          <div
            class="tab-pane fade tab_images"
            id="content2"
            role="tabpanel"
            aria-labelledby="content2-tab"
          >
            <VR src="https://aitrec.jp/PJ38/VR/HVR/Garden/tour.html" />
          </div>
          <div
            class="tab-pane fade tab_images"
            id="content3"
            role="tabpanel"
            aria-labelledby="content3-tab"
          >
            <VR src="https://aitrec.jp/VR/test/Tondaya/tour.html" />
          </div>
          <div
            class="tab-pane fade tab_images"
            id="content4"
            role="tabpanel"
            aria-labelledby="content4-tab"
          >
            <VR
              src="https://aitrec.jp/TagengoPJ/Saimyoji/VR/HVR/Keidai/tour.html"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import VR from "./atoms/VR.vue";

@Options({
  components: {
    VR
  }
})
export default class PanoramicImage extends Vue {}
</script>

<style scoped lang="scss">
.content {
  &_body {
    &__description {
      width: 60%;
      @media (max-width: 576px) {
        width: 90%;
      }
    }
  }
  .nav-tabs {
    height: 60px;
  }
  .nav-item {
    margin-left: 4px;
    &:nth-child(1) {
      margin: 0;
    }
  }
  .nav-link {
    line-height: 60px;
    border: 0;
    padding: 0;
    background-color: var(--lightblue);
    color: var(--white);
    &.active {
      color: var(--lightblue);
      background-color: var(--white);
    }
  }
  .tab_images {
    padding: 16px;
    background-color: var(--white);
  }
}
</style>
