<template>
  <div id="section_contact" class="content anchor">
    <MessageModal :msg="message" bgColor="background-color: var(--lightblue)" />
    <div class="content_header">
      <p>お問い合わせ</p>
    </div>
    <div class="container content_body">
      <div class="row">
        <div class="col-lg-5">
          <div class="card bg-primary">
            <h5 class="card_title">株式会社カリアーク</h5>
            <div class="card_info">
              <i class="fas fa-envelope" />
              <img src="@/assets/mail.png" />
            </div>
            <div class="card_info">
              <i class="fas fa-phone-alt" />
              <p>
                075-754-7710
              </p>
            </div>
            <div class="card_info">
              <i class="fas fa-map-marker-alt" />
              <p>
                〒615-8245<br />
                京都市西京区御陵大原1-36<br />
                京大桂ベンチャープラザ北館309
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <Form @submit="onSubmit" v-slot="{ errors }">
            <div class="form-group row">
              <label
                for="inputCompanyName"
                class="col-lg-4 col-sm-3 col-form-label"
              >
                会社名<br />
                Company Name
              </label>
              <div class="col-sm-8">
                <Field
                  name="companyName"
                  id="inputCompanyName"
                  type="text"
                  class="form-control"
                  as="input"
                  v-model="companyName"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                for="inputUserName"
                class="col-lg-4 col-sm-3 col-form-label"
              >
                担当者氏名*<br />
                Your Name
              </label>
              <div class="col-sm-8">
                <Field
                  name="userName"
                  id="inputUserName"
                  type="text"
                  class="form-control"
                  as="input"
                  :rules="isRequired"
                  v-model="userName"
                />
                <span class="error">{{ errors.userName }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail" class="col-lg-4 col-sm-3 col-form-label">
                メールアドレス*<br />
                Email
              </label>
              <div class="col-sm-8">
                <Field
                  name="email"
                  id="inputEmail"
                  type="email"
                  class="form-control"
                  as="input"
                  :rules="isRequired"
                  v-model="email"
                />
                <span class="error">{{ errors.email }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPhone" class="col-lg-4 col-sm-3 col-form-label">
                電話番号*<br />
                Phone Number
              </label>
              <div class="col-sm-8">
                <Field
                  name="phone"
                  id="inputPhone"
                  type=""
                  class="form-control"
                  as="input"
                  :rules="isRequired"
                  v-model="phone"
                />
                <span class="error">{{ errors.phone }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="inputInquiry"
                class="col-lg-4 col-sm-3 col-form-label"
              >
                お問い合わせ内容*<br />
                Inquiry
              </label>
              <div class="col-sm-8">
                <Field
                  name="inquiry"
                  id="inputInquiry"
                  class="form-control"
                  as="select"
                  :rules="isRequired"
                  v-model="inquiry"
                >
                  <option value="１．VRカメラの詳しい話を聞きたい">
                    １．VRカメラの詳しい話を聞きたい
                  </option>
                  <option value="２．VRカメラの価格を知りたい">
                    ２．VRカメラの価格を知りたい
                  </option>
                  <option value="３．超高画質360度画像のデモを見たい">
                    ３．超高画質360度画像のデモを見たい
                  </option>
                  <option value="４．代理店に関する話を聞きたい">
                    ４．代理店に関する話を聞きたい
                  </option>
                  <option value="５．その他">
                    ５．その他
                  </option>
                </Field>
                <span class="error">{{ errors.inquiry }}</span>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="inputMessage"
                class="col-lg-4 col-sm-3 col-form-label"
              >
                詳しい内容<br />
                Message
              </label>
              <div class="col-sm-8">
                <Field
                  name="content"
                  id="inputMessage"
                  class="form-control"
                  as="textarea"
                  rows="10"
                  v-model="content"
                />
              </div>
            </div>
            <input
              type="hidden"
              id="submitKey"
              name="submitKey"
              value="1234567"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-sm-3 col-form-label"></label>
              <div class="col-sm-8">
                <button
                  type="submit"
                  value="submit"
                  class="btn btn-outline-primary"
                >
                  送信
                </button>
                <p class="recaptcha_policy">
                  This site is protected by reCAPTCHA and the Google
                  <a href="https://policies.google.com/privacy"
                    >Privacy Policy</a
                  >
                  and
                  <a href="https://policies.google.com/terms"
                    >Terms of Service</a
                  >
                  apply.
                </p>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import { useReCaptcha } from "vue-recaptcha-v3";
import { Field, Form } from "vee-validate";

import MessageModal from "./atoms/MessageModal.vue";

@Options({
  components: {
    MessageModal,
    Field,
    Form
  }
})
export default class Contact extends Vue {
  companyName = "";
  userName = "";
  email = "";
  phone = "";
  inquiry = "";
  content = "";
  submitKey = "1234567";
  message = "";

  private recaptcha = useReCaptcha();

  isRequired(value: string | number) {
    return value ? true : "入力必須です / Required field";
  }

  async onSubmit() {
    const formData = new FormData();

    formData.append("companyName", this.companyName);
    formData.append("userName", this.userName);
    formData.append("email", this.email);
    formData.append("phone", this.phone);
    formData.append("inquiry", this.inquiry);
    formData.append("content", this.content);
    formData.append("submitKey", this.submitKey);

    const requestUrl = "/sendemail.php/";

    if (!this.recaptcha) {
      return;
    }
    const { executeRecaptcha, recaptchaLoaded } = this.recaptcha;
    await recaptchaLoaded();
    const token = await executeRecaptcha("login");
    formData.append("g-recaptcha-response", token);
    formData.append("action", "login");

    axios
      .post(requestUrl, formData, {
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
      .then(() => {
        this.message = "メールを送信しました";
        this.companyName = "";
        this.userName = "　";
        this.email = "　";
        this.phone = "　";
        this.inquiry = "　";
        this.content = "";
        this.showModal();
      })
      .catch(() => {
        this.message = "メールの送信に失敗しました";
        this.showModal();
      });
  }

  private showModal() {
    const messageModal = document.getElementById("messageModal");
    if (messageModal) {
      messageModal.classList.replace("msg_hide", "msg_appear");
      setTimeout(() => {
        messageModal.classList.replace("msg_appear", "msg_hide");
      }, 4000);
    }
  }
}
</script>

<style scoped lang="scss">
#section_contact {
  margin-bottom: 0;
  padding-bottom: 40px;
}
.content_body {
  &__header {
    font-size: 1.4rem;
    margin-bottom: 40px;
  }
  &__description {
    margin: 40px auto;
    text-align: start;
  }
  &__image {
    width: 100%;
    object-fit: cover;
  }
  .centered {
    width: 60%;
  }
  .started {
    text-align: start;
  }
}
.image--large {
  width: 100%;
  video {
    width: 100%;
  }
}
.card {
  padding: 40px 16px;
  width: 90%;
  color: var(--white);
  @media (max-width: 991px) {
    width: 60%;
    margin: 0 auto 40px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  &_title {
    margin-bottom: 16px;
    padding-left: 54px;
    font-weight: bold;
  }
  &_info {
    display: flex;
    i {
      margin-left: 8px;
      margin-right: 16px;
      font-size: 1.4rem;
      width: 30px;
    }
    img {
      height: 17px;
      width: auto;
      margin-top: 4px;
      margin-bottom: 20px;
    }
    .tel {
      width: 100px;
    }
  }
}
.col-form-label {
  @media (min-width: 576px) {
    text-align: end;
  }
}
.recaptcha_policy {
  padding: 0;
  margin-top: 16px;
  font-size: 11px !important;
  color: #444 !important;
}
.recaptcha_policy a {
  font-size: 11px !important;
  color: #111 !important;
}
.error {
  font-size: 0.8rem;
  color: red;
}
</style>
