<template>
  <div class="topImage" id="topImage">
    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-ride="carousel"
    >
      <ol class="carousel-indicators">
        <li
          data-target="#carouselExampleIndicators"
          data-slide-to="0"
          class="active"
        ></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="@/assets/Kongohukuji.jpg" alt="slide1" />
        </div>
        <div class="carousel-item">
          <img src="@/assets/NinnajiMomiji.jpg" alt="slide2" />
        </div>
        <div class="carousel-item">
          <img src="@/assets/NinnajiSakura2.jpg" alt="slide3" />
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carouselExampleIndicators"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
    <div class="topImage__colorFilter"></div>
    <div class="topImage__text mb-lg-5">
      <p>
        驚愕の<br />
        <span class="blue_outline"
          >超高画質360度<br class="break_sp" />VRコンテンツ</span
        >が<br />
        <span class="blue_glow">新たな世界</span
        ><br class="break_sp" />を切り開く
      </p>
      <p class="sub">"Culiarchが世界初の超高画質360度VRカメラを開発しました"</p>
    </div>
    <a
      role="button"
      class="btn btn-primary shadow"
      href="#section_panoramicImage"
    >
      → 超高画質を体験する
    </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Video from "./atoms/Video.vue";

@Options({
  components: {
    Video
  }
})
export default class TopImage extends Vue {
  // msg!: string;
}
</script>

<style scoped lang="scss">
.topImage {
  width: 100%;
  position: relative;
  video {
    width: 100%;
  }
  &__colorFilter,
  &__text {
    position: absolute;
    bottom: 0;
  }
  &__colorFilter {
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: var(--lightblue);
    opacity: 0.2;
  }
  &__text {
    margin-left: 40px;
    padding-top: 0;
    font-family: sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: var(--white);
    letter-spacing: 3px;
    line-height: 1.4;
    z-index: 15;
    .sub {
      font-size: 1rem;
      @media (max-width: 991px) {
        margin-bottom: 60px;
      }
    }
  }
}
@media (max-width: 992px) {
  .topImage__text {
    height: 100%;
    margin: 0 16px;
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.4rem;
    @media (max-width: 354px) {
      margin-left: 16px;
      font-size: 1.1rem;
    }
  }
}
.blue_glow {
  color: var(--white);
  text-shadow: 0 0 12px var(--lightblue);
  font-size: 3rem;
}
.blue_outline {
  color: var(--lightblue);
  text-shadow: 1px 1px 0 var(--white), -1px 1px 0 var(--white),
    1px -1px 0 var(--white), -1px -1px 0 var(--white);
  font-size: 3rem;
}
.carousel-inner {
  height: 42vw;
  z-index: 1;
  @media (max-width: 575px) {
    height: calc(100vh - 56px);
  }
  @media (min-width: 576px) and (max-width: 992px) {
    height: 100vh;
    max-height: 57vw;
  }
}
.carousel-item {
  width: 100%;
  height: 100%;
  * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.carousel-indicators {
  left: auto;
  margin-right: 0;
  margin-left: 0;
  width: 20vw;
  z-index: 20;
}
.carousel-control-prev,
.carousel-control-next {
  z-index: 20;
}
.btn {
  position: absolute;
  bottom: 0;
  left: 40px;
  z-index: 30;
  width: 200px;
  border-radius: 24px;
  margin-bottom: 12px;
}
.blue_glow,
.blue_outline {
  font-size: 2.5rem;
  @media (max-width: 354px) {
    font-size: 2rem;
  }
}
.btn {
  width: 200px;
  left: 16px;
}
</style>
