<template>
  <header>
    <nav
      class="navbar navbar-expand-lg navbar-light d-flex justify-content-between align-items-center"
    >
      <a class="navbar-brand header_logo" href="/">
        <img alt="Vue logo" src="../assets/culiarch_logo.png" />
        culiarch
      </a>
      <button
        class="navbar-toggler"
        id="menuBar"
        type="button"
        data-toggle="collapse"
        data-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarToggler"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              :class="['nav-link', currentNavi() === 'feature' ? 'active' : '']"
              href="#section_feature"
              @click="closeModal"
              >特徴</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="[
                'nav-link',
                currentNavi() === 'panoramicImage' ? 'active' : ''
              ]"
              href="#section_panoramicImage"
              @click="closeModal"
              >超高画質360度画像</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="[
                'nav-link',
                currentNavi() === 'practicalUse' ? 'active' : ''
              ]"
              href="#section_practicalUse"
              @click="closeModal"
              >活用例</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="['nav-link', currentNavi() === 'spec' ? 'active' : '']"
              href="#section_spec"
              @click="closeModal"
              >仕様</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="['nav-link', currentNavi() === 'contact' ? 'active' : '']"
              href="#section_contact"
              @click="closeModal"
              >お問い合わせ</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class VrHeader extends Vue {
  private scrollY = 0;
  private sectionOffsetTop: number[] = [];

  currentNavi() {
    if (
      this.sectionOffsetTop[0] - 40 <= this.scrollY &&
      this.scrollY < this.sectionOffsetTop[1] - 50
    ) {
      return "feature";
    } else if (
      this.sectionOffsetTop[1] - 50 <= this.scrollY &&
      this.scrollY < this.sectionOffsetTop[2] - 50
    ) {
      return "panoramicImage";
    } else if (
      this.sectionOffsetTop[2] - 50 <= this.scrollY &&
      this.scrollY < this.sectionOffsetTop[3] - 50
    ) {
      return "practicalUse";
    } else if (
      this.sectionOffsetTop[3] - 50 <= this.scrollY &&
      this.scrollY < this.sectionOffsetTop[4] - 50
    ) {
      return "spec";
    } else if (this.sectionOffsetTop[4] - 50 <= this.scrollY) {
      return "contact";
    } else {
      return "";
    }
  }

  mounted() {
    window.addEventListener("scroll", () => {
      this.pushScrollY();
    });
    window.addEventListener("resize", () => {
      this.pushScrollY();
    });
    window.addEventListener("load", () => {
      this.pushElementOffsetTop();
    });
  }

  private pushScrollY() {
    this.scrollY = window.scrollY;
  }

  private pushElementOffsetTop() {
    const targets = [
      "section_feature",
      "section_panoramicImage",
      "section_practicalUse",
      "section_spec",
      "section_contact"
    ];
    targets.forEach(target => {
      const element = document.getElementById(target);
      let offsetTop = 0;
      if (element != null) {
        offsetTop = Math.round(
          window.scrollY + element.getBoundingClientRect().top
        );
      }
      this.sectionOffsetTop.push(offsetTop);
    });
  }

  closeModal() {
    const navbarToggler = document.getElementById("navbarToggler");
    const menuBar = document.getElementById("menuBar");
    if (navbarToggler && menuBar) {
      navbarToggler.classList.remove("show");
      menuBar.setAttribute("aria-expanded", "false");
    }
  }
}
</script>

<style scoped lang="scss">
header {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 80px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--white);
}
.navbar {
  max-width: 980px;
  margin: 0 auto;
}
.navbar-light .navbar-toggler {
  border: none;
}
.header_logo {
  display: flex;
  height: 40px;
  font-size: 1.5rem;
  color: var(--lightblue);
  img {
    height: 100%;
    padding-right: 12px;
  }
}
.nav-item {
  &.active {
    font-weight: bold;
  }
  @media (max-width: 991px) {
    &:first-child {
      margin-top: 1rem;
    }
  }
}
.nav-link {
  padding-left: 40px !important;
  text-decoration: none;
  color: black;
  &:hover {
    color: var(--lightblue) !important;
  }
}
button[aria-expanded="true"] {
  > span {
    display: none;
  }
  &::after {
    content: "×";
    font-size: 1.7rem;
  }
}
</style>
