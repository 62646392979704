
import { Options, Vue } from "vue-class-component";

import VrHeader from "@/components/VrHeader.vue";
import VrFooter from "@/components/VrFooter.vue";
import TopImage from "@/components/TopImage.vue";
import Feature from "@/components/Feature.vue";
import PanoramicImage from "@/components/PanoramicImage.vue";
import PracticalUse from "@/components/PracticalUse.vue";
import Spec from "@/components/Spec.vue";
import VrContact from "@/components/VrContact.vue";

@Options({
  components: {
    VrHeader,
    VrFooter,
    TopImage,
    Feature,
    PanoramicImage,
    PracticalUse,
    Spec,
    VrContact
  }
})
export default class VrCamera extends Vue {}
