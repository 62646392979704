<template>
  <div id="section_feature" class="content anchor">
    <div class="content_header">
      <p>特徴</p>
    </div>
    <div class="content_body">
      <div class="container">
        <p class="content_body__header">
          高解像度<br class="break__sp" />
          (ハイレゾリューション)<br class="break__sp" />
          撮影が可能
        </p>
        <p class="content_body__description centered">
          焦点距離のより大きい望遠レンズや高品質レンズで撮影することによって、立体角あたりの撮影画素数を増やす。そして、撮影した画像を結合することによって50K~200K高解像度パノラマ画像を作成できる撮影技術を確立。
        </p>
      </div>
      <Video
        :src="require('@/assets/ninnaji.mp4')"
        class="top_video"
        alt="zoomOut"
      />
      <div class="compared_image__title blue_line">
        ズームにしてもくっきり！
      </div>
      <div class="row mx-0">
        <div class="col-sm-6 pl-sm-5 compared_image">
          <img v-lazy="require('@/assets/100K.jpg')" alt="" />
          <p>100Kの画像</p>
        </div>
        <div class="col-sm-6 pr-sm-5 compared_image">
          <img v-lazy="require('@/assets/8K.jpg')" alt="" />
          <p>8K相当の画像</p>
        </div>
      </div>
      <div class="container px-0 mt-sm-5">
        <div class="row mx-0 py-3 py-lg-5">
          <div class="col-sm pr-sm-4">
            <p class="content_body__header text-sm-left">
              広照度<br class="break__sp" />
              (ハイダイナミックレンジ)<br class="break__sp" />
              撮影が可能
            </p>
            <p class="content_body__description">
              多段階露出撮影を用いて、360度全ての方向において光量を調節することが可能になり、暗いところから明るいところまでの幅ひろい照度の鮮明な画像を作成できる撮影(HDR撮影)技術を開発
            </p>
          </div>
          <div class="col-sm px-0">
            <img
              class="content_body__image"
              v-lazy="require('@/assets/high_dynamic_range.jpg')"
              alt=""
            />
          </div>
        </div>
        <div class="row mx-0 py-3 py-lg-5 xs_reverse">
          <div class="col-sm px-0">
            <Video :src="require('@/assets/vr_camera.mp4')" />
          </div>
          <div class="col-sm pl-sm-4">
            <p class="content_body__header text-sm-left">撮影の自動化を実現</p>
            <p class="content_body__description">
              市販の高性能カメラ（本体）と高品質レンズを最適に組み合わせた光学設計を行いながら、自動化多軸ステージ機構を開発することにより撮影の自動化を実現
            </p>
          </div>
        </div>
      </div>
      <p class="content_body__header">様々な便利機能</p>
      <div class="card_list">
        <div class="card">
          <img src="@/assets/person.png" alt="pose" loading="lazy" />
          <p>
            通行人が多い現場での撮影に対応するポーズ機能
          </p>
        </div>
        <div class="card">
          <img src="@/assets/battery.png" alt="battery" loading="lazy" />
          <p>
            AC電源のない現場での撮影を可能とする大容量バッテリーの装置
          </p>
        </div>
        <div class="card">
          <img src="@/assets/memory.png" alt="memory" loading="lazy" />
          <p>
            多彩なデータ保存媒体に対応（内部半導体メモリ/ 外部HDD/ SIMカード）
          </p>
        </div>
        <div class="card">
          <img src="@/assets/watch.png" alt="view" loading="lazy" />
          <p>
            撮影状況を常時監視できるLiveView機能
          </p>
        </div>
        <div class="card">
          <img src="@/assets/wifi.png" alt="remote" loading="lazy" />
          <p>
            離れた場所からカメラやモーターとコントロールできるリモートコントロール機能
          </p>
        </div>
        <div class="card">
          <img src="@/assets/cloud.png" alt="wifi" loading="lazy" />
          <p>
            Wi-Fiネットワーク経由でパソコンへデータを保存するネットワークフォルダ機能
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Video from "./atoms/Video.vue";

@Options({
  components: {
    Video
  }
})
export default class Feature extends Vue {
  // msg!: string;
}
</script>

<style scoped lang="scss">
.content_body {
  text-align: center;
  &__description {
    text-align: start;
  }
  &__image {
    width: 100%;
    object-fit: cover;
  }
}
.centered {
  @media (min-width: 576px) {
    width: 60%;
  }
}
.top_video {
  @media (max-width: 576px) {
    height: 80vw;
    object-fit: cover;
  }
}
.image--large {
  width: 100%;
  video {
    width: 100%;
  }
}
.compared_image {
  padding-bottom: 32px;
  margin-bottom: 32px;
  height: 60vw;
  max-height: 600px;
  &__title {
    margin: 40px auto;
    width: fit-content;
    padding: 0 12px;
    font-size: 1.3rem;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20%; /*角丸*/
  }
  p {
    margin-top: 16px;
    font-size: 1.3rem;
  }
}
.card_list {
  margin-left: calc(50% - 50vw + 8px);
  width: calc(100vw - 16px);
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 576px) and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.card {
  align-items: center;
  padding: 16px;
  margin: 2px;
  border: 4px solid var(--lightblue);
  @media (max-width: 768px) {
    padding: 12px;
  }
  img {
    width: 40%;
    object-fit: contain;
    margin: 8px 0 16px;
  }
  @media (max-width: 576px) {
    p {
      margin-bottom: 0;
    }
  }
}
.xs_reverse {
  @media (max-width: 576px) {
    flex-direction: column-reverse;
  }
}
</style>
