
import { Options, Vue } from "vue-class-component";

import VR from "./atoms/VR.vue";

@Options({
  components: {
    VR
  }
})
export default class PanoramicImage extends Vue {}
